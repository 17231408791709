<template>
  <div :class="runtimeConfig.app.backgroundColor">
    <FlashMessage />
    <slot />
  </div>
</template>

<script setup lang="ts">
const runtimeConfig = useRuntimeConfig();
const route = useRoute();

useHead({
  title: "Otago",
  link: [
    { rel: "shortcut icon", href: "/favicon.ico" },
    { rel: "canonical", href: `${runtimeConfig.app.baseUrl}${route.path}` },
  ],
  bodyAttrs: {
    class: "dark",
  },
});

watch(
  () => route.hash,
  () => {
    if (!route.hash) return;
    const el = document.getElementById(route.hash.substring(1));
    if (el) setTimeout(() => el.scrollIntoView({ behavior: "smooth" }), 10);
  },
);

watch(() => route.params.company_slug, refreshUserData);
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

body {
  @apply bg-white dark:bg-zinc-900 text-white;
  margin: 0;
}

:root {
  color-scheme: dark;
}

::selection {
  background-color: #2babe1;
  color: #fff;
}

.modal {
  .fixed > div > div {
    @apply dark:bg-zinc-800 !important;
  }
}

.docs-titles {
  h1 {
    @apply text-3xl mb-4 font-extrabold tracking-tight leading-none dark:text-blue-400;
  }
  a h2 {
    @apply pt-8 mb-3 flex items-center text-lg font-semibold tracking-wide uppercase dark:text-blue-400;

    svg {
      @apply mr-2;
    }
  }
  a h3 {
    @apply pt-6 mb-3 text-lg font-medium dark:text-blue-400;
  }
  h4 {
    @apply text-gray-300;
  }
}
</style>
