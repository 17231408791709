<template>
  <div v-if="flash" class="toast">
    <FwbToast :type="flash.type" closable>
      <span class="whitespace-pre-wrap">{{ flash.message }}</span>
    </FwbToast>
  </div>
</template>

<script setup lang="ts">
import { FwbToast } from "flowbite-vue";

const flash = useFlashMessage();

if (import.meta.browser) {
  const flashCookie = useCookie("flash-message");

  if (flashCookie?.value && typeof flashCookie.value === "object") {
    const { type, message, durationMs } = flashCookie.value;
    setFlashMessage({ type, message, durationMs });

    flashCookie.value = null;
  }
}
</script>

<style scoped>
.toast {
  @apply flex fixed top-4 justify-center w-full;
  z-index: 9999;
}
</style>
